<template>
	<EstContainer v-if="!loadSysBaseInfo.isLoad && $route.meta.loadContainer === true"></EstContainer>
	<router-view v-else-if="!loadSysBaseInfo.isLoad" />
</template>

<script>
	import '@/assets/css/main.less'
	import {
		getCurrentInstance,
		defineComponent,
		ref,
		reactive
	} from 'vue'
	import EstContainer from '@/components/common/EstContainer.vue'
	export default defineComponent({
		name: 'App',
		components: {
			EstContainer
		},
		setup() {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties

			// 加载系统基础信息标识
			var loadSysBaseInfo = reactive({
				isLoad: true
			})

			// 获取系统基础配置信息
			ctx.$request_.post(ctx.$api_.state.Home.BaseInfo.sys_base_info.url)
				.then(respon => {
					if (respon.Code === 200) {
						ctx.$system_.commit('setSysInfo',respon.Data)
						loadSysBaseInfo.isLoad = false
					} else {
						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'warning'
						})
					}
				})

			return {
				loadSysBaseInfo
			}
		}
	})
</script>
