import {
	createStore
} from 'vuex'
import pubApi from '@/assets/json/pub/api/index.json'
import homeApi from '@/assets/json/home/api/index.json'
import taskApi from '@/assets/json/task/api/index.json'

export default createStore({
	state: {
		Pub: pubApi,
		CurrentPath: localStorage.getItem('current_path') ? localStorage.getItem('current_path') : '',
		Home: homeApi,
		Task: taskApi
	},
	mutations: {
		setCurrentPath(state, data) {
			if (data) {
				localStorage.setItem('current_path', data)
			} else {
				localStorage.removeItem('current_path')
			}

			state.CurrentPath = data
		}
	},
	actions: {},
	modules: {}
})
