import {
	createApp
} from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import system_ from '@/store/common/system.js'
import api_ from '@/store/common/api.js'
import code_ from '@/store/common/code.js'
import menu_ from '@/store/common/menu.js'
import task_ from '@/store/task/index.js'
import request_ from "@/utils/request"
import request_nl_ from "@/utils/request_nl"
import pubFun_ from '@/assets/js/pub_fun'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus, {
	locale: zhCn
})

app.config.globalProperties.$system_ = system_
app.config.globalProperties.$api_ = api_
app.config.globalProperties.$code_ = code_
app.config.globalProperties.$menu_ = menu_
app.config.globalProperties.$task_ = task_
app.config.globalProperties.$request_ = request_
app.config.globalProperties.$request_nl_ = request_nl_
app.config.globalProperties.$pubFun_ = pubFun_

app.mount('#app')
