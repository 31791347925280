import {
	createStore
} from 'vuex'

export default createStore({
	state: {
		applic_name: "", //系统名称
		applic_logo: "", //系统logo
		applic_bg: "", //系统背景图
		soft_info: "", //软著信息
		copyright_company: "", //版权所属公司名称
		copyright_telephone: "", //板块所属公司联系电话
		system_record_no: "", //备案号
	},
	mutations: {
		setSysInfo(state, data) {
			if (data.applic_name) {
				state.applic_name = data.applic_name
			}
			if (data.applic_logo) {
				state.applic_logo = data.applic_logo
			}
			if (data.applic_bg) {
				state.applic_bg = data.applic_bg
			}
			if (data.soft_info) {
				state.soft_info = data.soft_info
			}
			if (data.copyright_company) {
				state.copyright_company = data.copyright_company
			}
			if (data.copyright_telephone) {
				state.copyright_telephone = data.copyright_telephone
			}
			if (data.system_record_no) {
				state.system_record_no = data.system_record_no
			}
		}
	},
	actions: {},
	modules: {}
})
