/* 系统主模块 */
export default [{
	meta: {
		title: '云享库-系统首页',
		loadContainer: true,
		module: 'Home',
		left_menu_index: 1
	},
	path: '/',
	name: 'Home',
	component: () => import('@/views/Home/Index.vue') // 系统首页
}, {
	meta: {
		title: '云享库-用户登录',
		module: 'Login'
	},
	path: '/SignIn',
	name: 'SignIn',
	component: () => import('@/views/Home/Login.vue') // 用户登录
}, {
	meta: {
		title: '云享库-扫码认证',
		module: 'Login'
	},
	path: '/QrcodeAuth',
	name: 'QrcodeAuth',
	component: () => import('@/views/Home/QrcodeAuth.vue') // 扫码认证
}, {
	meta: {
		title: '云享库-授权登录',
		module: 'Login'
	},
	path: '/SignInit',
	name: 'SignInit',
	component: () => import('@/views/Home/LoginInit.vue') // 授权登录
}, {
	meta: {
		title: '云享库-单点登录',
		module: 'Login'
	},
	path: '/SignSso',
	name: 'SignSso',
	component: () => import('@/views/Home/LoginSso.vue') // 单点登录
}]
