import {
	createStore
} from 'vuex'

export default createStore({
	state: {
		task_manage: {
			task_type: [],
			product_list: [],
			work_module: [],
			team_list: [],
			urgency_list: [1, 2, 3, 4]
		}
	},
	mutations: {
		setTaskManageToTaskType(state, data) {
			// 更新任务管理-任务类型值
			state.task_manage.task_type = data
		},
		setTaskManageToProductList(state, data) {
			// 更新任务管理-产品信息值
			state.task_manage.product_list = data
		},
		setTaskManageToWorkModule(state, data) {
			// 更新任务管理-板块信息值
			state.task_manage.work_module = data
		},
		setTaskManageToTeamList(state, data) {
			// 更新任务管理-团队信息值
			state.task_manage.team_list = data
		}
	},
	modules: {}
})
