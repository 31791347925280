import {
	createStore
} from 'vuex'
import homeMenu from '@/assets/json/home/menu/index.json'
import taskMenu from '@/assets/json/task/menu/index.json'
import workShareMenu from '@/assets/json/work_share/menu/index.json'

export default createStore({
	state: {
		list: [
			homeMenu,
			taskMenu,
			workShareMenu
		],
		roleNode: []
	},
	mutations: {
		setRoleNode(state, data) {
			if (data) {
				state.roleNode = data
			}
		}
	},
	modules: {}
})
