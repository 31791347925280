import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
import Home from './home/home.js'
import Task from './task/task.js'

let baseRoutes = []
let routes = baseRoutes.concat(Home, Task)

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

router.beforeEach((to, from, next) => {
	// 设置当前页面标题
	if (to.meta.title) {
		document.title = to.meta.title
	}

	next()
})

export default router
