import CryptoJS from 'crypto-js'

const pcj = {
	pub_key: 'sCUrZer08XWwDfiGSszU2ccZwaedesFh',
	iv: '',
	resp: [],
	init: () => {
		if (pcj.pub_key && pcj.iv == '') {
			pcj.pub_key = CryptoJS.enc.Utf8.parse(pcj.pub_key)
			pcj.iv = (CryptoJS.SHA1(pcj.pub_key).toString()).substr(18, 16)
			pcj.iv = CryptoJS.enc.Utf8.parse(pcj.iv)
		}
	},
	aesEncrypt: (data) => {
		/* AES加密 */
		// 加载基础配置
		pcj.init()
		
		// 进行加密操作
		if (typeof data === 'object') {
			let srcs = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
			let encrypted = CryptoJS.AES.encrypt(srcs, pcj.pub_key, {
				iv: pcj.iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7
			})
			return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
		} else {
			return false
		}
	},
	aesDecrypt: (response) => {
		/* AES解密*/
		// 加载基础配置
		pcj.init()

		// 查询请求信息
		if (response.data.Code === 200 && response.data.Data.encryption) {
			// 执行解密操作
			let encryption = response.data.Data.encryption
			let base64 = CryptoJS.enc.Base64.parse(encryption)
			let src = CryptoJS.enc.Base64.stringify(base64)
			let decrypt = CryptoJS.AES.decrypt(src, pcj.pub_key, {
				iv: pcj.iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7
			})

			// 删除加密信息
			delete response.data.Data.encryption

			//获取解密后的信息
			let datas = decrypt.toString(CryptoJS.enc.Utf8)

			if (datas == '') {
				response.data.Code = 202
				response.data.Message = '数据解密失败'
			} else {

				response.data.Data = JSON.parse(datas)
			}
		}

		return response.data
	},
	aesDecryptToDirect: (encryption) => {
		/* AES解密*/
		// 加载基础配置
		pcj.init()
	
		// 执行解密操作
		let base64 = CryptoJS.enc.Base64.parse(encryption)
		let src = CryptoJS.enc.Base64.stringify(base64)
		let decrypt = CryptoJS.AES.decrypt(src, pcj.pub_key, {
			iv: pcj.iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7
		})
	
		return decrypt.toString(CryptoJS.enc.Utf8)
	}
}


export default pcj
