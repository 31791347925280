<template>
	<div id="EstContainer">
		<el-container class="main">
			<el-header class="header">
				<div class="top-logo">
					<img class="logo" :src="$system_.state.applic_logo" alt="logo">
				</div>
				<div class="top-menu" style="display: inline-block;">
					<div class="menu-list">
						<el-tabs v-model="$route.meta.module" @tab-click="choseTopMenu">
							<el-tab-pane :label="v.name" :name="v.module" :disabled="v.disabled"
								v-for="(v,i) in topModule.list"></el-tab-pane>
						</el-tabs>
					</div>
				</div>
				<el-row class="top-right">
					<el-col :span="15" class="user-info">
						<div class="user-name">{{userInfo.user_name}}</div>
						<div class="user-duty">{{userInfo.duty_name}}</div>
					</el-col>
					<el-col :span="9">
						<el-dropdown>
							<el-avatar class="user-logo" :src="userInfo.logo_url">
							</el-avatar>
							<template #dropdown>
								<el-dropdown-menu>
									<router-link class="user-logo-exit" :to="{path:'SignIn'}">
										<el-dropdown-item icon="el-icon-switch-button">
											退出系统
										</el-dropdown-item>
									</router-link>
								</el-dropdown-menu>
							</template>
						</el-dropdown>
					</el-col>
				</el-row>
			</el-header>
			<el-container>
				<el-aside class="left-aside">
					<el-menu :default-active="String($route.meta.left_menu_index)" :router="true">
						<el-menu-item :index="String(v.index)" :route="v.path"
							:disabled="v.disabled ? v.disabled : false" v-for="(v,i) in leftMenu.list">
							<i class="iconfont" v-if="v.icon" v-html="v.icon"></i> {{v.name}}
						</el-menu-item>
					</el-menu>
				</el-aside>
				<el-container>
					<el-main>
						<router-view v-if="parseInt(userInfo.user_id) > 0 && userInfo.isGetRoleNode === true" />
					</el-main>
					<el-footer class="footer">
						<div>开发者：{{$system_.state.copyright_company}}
							<span v-if="$system_.state.soft_info">{{$system_.state.applic_name}}
								{{$system_.state.soft_info}}</span>
						</div>
						<div>Copyright {{$system_.state.copyright_company}} {{$system_.state.copyright_telephone}}
						</div>
					</el-footer>
				</el-container>
			</el-container>
		</el-container>
	</div>
</template>
<script>
	import {
		getCurrentInstance,
		defineComponent,
		ref,
		reactive,
		watch
	} from 'vue'
	import {
		useRoute
	} from "vue-router"
	export default defineComponent({
		name: 'App',
		setup() {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties

			// 顶部模块信息
			const topModule = reactive({
				list: [ctx.$menu_.state.list[0]]
			})

			// 点击顶部模块
			const choseTopMenu = (menu) => {
				ctx.$router.push({
					path: topModule.list[menu.index].path
				})
			}

			// 获取左侧菜单
			const leftMenu = reactive({
				list: []
			})

			// 用户信息
			const userInfo = reactive({
				user_id: '',
				user_name: '',
				duty_name: '',
				logo_url: '',
				isGetRoleNode: false
			})

			// 动态设置顶部模块、左侧菜单
			const setModuleAndMenu = () => {
				// 循环顶部模块获取当前模块api_token
				let module_token = ''
				ctx.$menu_.state.list.forEach((v, i) => {
					if (ctx.$route.meta.module === v.module) {
						if (typeof v.api_token === 'string') {
							module_token = v.api_token
						} else {
							module_token = ''
						}
					}
				})

				// 请求接口获取权限信息
				ctx.$request_.post(ctx.$api_.state.Home.BaseInfo.user_role.url, {
						module_token: module_token
					})
					.then((respon) => {
						if (respon.Code === 200) {
							/*顶部模块*/
							// 重置模块、菜单信息
							topModule.list = []
							leftMenu.list = []

							// 循环处理模块
							ctx.$menu_.state.list.forEach((v, i) => {
								// 判断模块是否存在api_token
								if (typeof v.api_token === 'string') {
									// 判断api_token是否进行授权
									if (respon.Data.module_range_list.indexOf(v.api_token) > -1) {
										topModule.list.push(v)
									}
								} else {
									// 未设置则默认授权
									topModule.list.push(v)
								}
							})

							/*左侧菜单*/
							if (respon.Data.menu_node_list === false) {
								// 无当前模块访问范围
								ctx.$notify({
									title: '温馨提示',
									message: '您没有当前模块访问权限',
									type: 'warning'
								})
							} else {
								// 循环顶级模块
								ctx.$menu_.state.list.forEach((v, i) => {
									if (v.module === ctx.$route.meta.module) {
										v.list.forEach((vv, ii) => {
											// 左侧菜单控制
											if (respon.Data.menu_node_list === true ||
												typeof vv.api_token === 'undefined') {
												// 未设置则默认授权
												leftMenu.list.push(vv)
											} else {
												// 判断api_token是否进行授权
												if (respon.Data.menu_node_list.indexOf(vv
														.api_token) > -1) {
													leftMenu.list.push(vv)
												}
											}
										})
									}
								})
							}

							/*存放当前模块权限信息*/
							ctx.$menu_.commit('setRoleNode', respon.Data.menu_node_list)

							userInfo.isGetRoleNode = true
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})
						}
					})
			}

			// 获取用户信息
			ctx.$request_.post(ctx.$api_.state.Home.BaseInfo.user_info.url)
				.then((respon) => {
					if (respon.Code === 200) {
						userInfo.user_id = respon.Data.u_id
						userInfo.user_name = respon.Data.u_name
						userInfo.duty_name = respon.Data.du_name
						userInfo.logo_url = respon.Data.hpath

						// 动态设置顶部模块、左侧菜单
						setModuleAndMenu()
					} else {
						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'warning'
						})
					}
				})

			// 监听路由改变事件
			const route = useRoute()
			watch(() => route.path, () => {
				// 验证是否已经加载用户信息
				if (parseInt(userInfo.user_id) > 0 && (typeof route.meta.module === 'undefined' || route
						.meta.module != 'Login')) {
					setModuleAndMenu()
				}
			})

			return {
				userInfo,
				topModule,
				choseTopMenu,
				leftMenu
			}
		}
	})
</script>
<style lang="less">
	#EstContainer {
		height: 100%;

		.main {
			height: 100%;

			.header {
				padding: 0;
				height: 3.35vw;
				background: #ffffff;
				border-bottom: solid 1px #e6e6e6;

				.top-logo {
					float: left;
					width: 10%;

					.logo {
						width: 6.5vw;
						padding-top: 0.25vw;
						padding-left: 1.15vw;
					}
				}

				.top-menu {
					width: 80%;

					.menu-list {
						padding: 0 1.65vw;
						-webkit-touch-callout: none;
						-webkit-user-select: none;
						-khtml-user-select: none;
						-moz-user-select: none;
						-ms-user-select: none;
						user-select: none;

						.el-tabs__header {
							height: 3.3vw;
							line-height: 3.3vw;

							.el-tabs__nav-wrap::after {
								position: static !important;
							}
						}
					}
				}

				.top-right {
					float: right;
					width: 10%;
					padding-right: 1.7vw;

					.user-info {
						text-align: right;
						padding-top: 0.4vw;

						.user-name {
							font-size: 0.8vw;
							color: #333333;

						}

						.user-duty {
							font-size: 0.6vw;
							color: #666666;
						}
					}

					.user-logo {
						margin-left: 0.6vw;
						margin-top: 0.27vw;
						width: 2.3vw;
						height: 2.3vw;
						cursor: pointer;

						img {
							width: 100%;
						}
					}
				}
			}

			.left-aside {
				width: 10.4vw;
				text-align: center;

				.el-menu {
					height: 100%;
					background-color: #fafbfc;
					-webkit-touch-callout: none;
					-webkit-user-select: none;
					-khtml-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
				}
			}

			.el-main {
				height: 0;
				background-color: #fafbfc;
			}

			.footer {
				background-color: #eeeeee;
				padding-top: 0.5vw;

				div {
					font-size: 0.75vw;
				}
			}
		}
	}

	.user-logo-exit {
		text-decoration: none;
	}
</style>
