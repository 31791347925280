/* 任务管理模块 */
export default [{
	meta: {
		title: '云享库-任务统计',
		loadContainer: true,
		module: 'Task',
		left_menu_index: 1
	},
	path: '/TaskStatistics',
	name: 'TaskStatistics',
	component: () => import('@/views/Task/TaskStatistics.vue') // 任务统计
}, {
	meta: {
		title: '云享库-我的任务',
		loadContainer: true,
		module: 'Task',
		left_menu_index: 2
	},
	path: '/MyTask',
	name: 'MyTask',
	component: () => import('@/views/Task/MyTask.vue') // 我的任务
}, {
	meta: {
		title: '云享库-产品管理',
		loadContainer: true,
		module: 'Task',
		left_menu_index: 3
	},
	path: '/Product',
	name: 'Product',
	component: () => import('@/views/Task/Product.vue') // 产品管理
}, {
	meta: {
		title: '云享库-任务管理',
		loadContainer: true,
		module: 'Task',
		left_menu_index: 4
	},
	path: '/TaskManage',
	name: 'TaskManage',
	component: () => import('@/views/Task/TaskManage.vue') // 任务管理
}, {
	meta: {
		title: '云享库-团队管理',
		loadContainer: true,
		module: 'Task',
		left_menu_index: 5
	},
	path: '/TaskTeam',
	name: 'TaskTeam',
	component: () => import('@/views/Task/TaskTeam.vue') // 任务管理
}, {
	meta: {
		title: '云享库-测试管理',
		loadContainer: true,
		module: 'Task',
		left_menu_index: 6
	},
	path: '/TestManage',
	name: 'TestManage',
	component: () => import('@/views/Task/TestManage.vue') // 测试管理
},
{
	meta: {
		title: '云享库-绩效考核',
		loadContainer: true,
		module: 'Task',
		left_menu_index: 7
	},
	path: '/PerformaAppraisal',
	name: 'PerformaAppraisal',
	component: () => import('@/views/Task/PerformaAppraisal.vue') // 绩效考核
}]
